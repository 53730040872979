import React from 'react'
/* import {Link} from 'react-router-dom' */
import heroB from '../../images/work/Queless.jpg'
import logoB from '../../images/work/Queless/logo.svg'
import workB1 from '../../images/work/Queless/work2.svg'
import workB2 from '../../images/work/Queless/work3.svg'
import workB3 from '../../images/work/Queless/work1.svg'
import workB4 from '../../images/work/Queless/work5.png'
import workB5 from '../../images/work/Queless/work6.png'
import workB6 from '../../images/work/Queless/work7.png'
import workB7 from '../../images/work/Queless/work11.svg'
import workB8 from '../../images/work/Queless/work9.svg'
import workB9 from '../../images/work/Queless/work6.svg'
import workB10 from '../../images/work/Queless/work12.svg'


const Queless = () => {

    return (
        <>
        <section class="text-gray-600 body-font w-full font-light">
            <div class="flex flex-col items-center align-middle justify-center py-24 mx-auto rounded-lg max-w-3xl">
                <img class="object-cover object-center w-full rounded-xl" alt="hero" src={heroB}/>
                <h1 class="py-10 max-w-6xl text-2xl text-center font-normal  text-gray md:text-3xl lg:text-3xl lg:max-w-9xl">
                            Queless
                </h1>
            </div>
            
        </section>
        {/* The brief Section  */}
        <section class="font-light">
            <div class="relative items-center w-full px-5  mx-auto md:px-12 lg:px-24 max-w-7xl">
            <div class="flex flex-row flex-wrap md:flex-nowrap  w-full gap-12 mx-auto ">
                
                <div class=" md:basis-3/4 p-6 text-center md:text-left">
                    <h1 class="text-gray text-3xl lg:text-5xl  mb-3"><span class="text-primary text-xl lg:text:2xl  text-semi">01.<br/></span>The Brief</h1>
                    <p class="px-3 text-gray leading-relaxed text-base">Queless is a Food delivery app that specifically caters to movie theaters. Users will be able to order food to seat and make payment directly from the app. They even negotiated a special deal with movie theaters for special counter. The team wanted us to deal with all the branding, communications and designs for the App. Later they pivioted towards contactless ordering during the pandemic. </p><br/>
                    <p class="px-3 text-gray leading-relaxed text-base">Queless team is young and playful and wanted that to reflect in all their communications and designs. The entire design has been executed and delivered in a week to meet their targets. </p>   
                </div>
                <div class=" w-full md:basis-1/4 p-6 text-base text-gray text-center md:text-left">
                    <p class="invisible text-8xl md:visible"><br/></p>
                    <p class="pb-5 " ><span class="text-primary text-2xl lg:text:3xl  text-semi">Industry</span><br/> Education </p>
                    <p class="pb-5 "><span class="text-primary text-2xl lg:text:3xl text-semi">Website</span><br/> https://queless.in(inactive)</p>
                    <p class="pb-5 "><span class="text-primary text-2xl lg:text:3xl text-semi">Services</span><br/> Branding | Logo Design | Brand Communication | UI Design | Web Development | SEO SMM</p>
                </div>
            </div>
            </div>
        </section>

        {/* The Branding Section  */}
        <section class="font-light">
            <div class="relative items-center w-full px-5 py-12 mx-auto md:px-12 lg:px-24 max-w-7xl">
            <div class=" items-center p-0 text-center md:text-left ">
                    <h1 class="text-gray text-3xl lg:text-5xl mb-3"><span class="text-primary text-xl lg:text:2xl  text-semi">02.<br/></span>The Branding</h1>
                    <p class="px-3 pb-10 text-gray leading-relaxed text-base">As per the brief we wanted to showcase the brand mission to reduce the time taken to service customer and to improve the  customer experience. Keeping this in mind the  we created a logo that conveys the same and mascot to showcase brands playful ethos</p>   
                    <img class=" px-5 lg:px-30  object-cover object-center w-full rounded-xl" alt="hero" src={logoB}/>
                    
                    <div class="flex flex-row  md:flex-nowrap  w-full gap-0 mx-auto ">
                        <div class=" basis-1/2 "><img class="object-fill px-5 py-10  w-full rounded-xl " alt="hero" src={workB1}/></div>
                        <div class=" basis-1/2 "><img class="object-fill px-5 py-10  w-full rounded-xl" alt="hero" src={workB2}/></div>    
                    </div>
                    
                    <p class="px-3 text-gray leading-relaxed text-base">Once the brand Identy is finalized we worked on creating persona for the customer to show case high regard for customer experience and playful attitude. </p>
            </div>

            {/* The Gallery start  */}
            <div class="flex flex-row flex-wrap md:flex-nowrap items-center w-full gap-0 mx-auto "> 
                <div class="  basis-1/2 text-center md:text-left">
                    <img class=" p-5  w-full rounded-xl " alt="hero" src={workB3}/>
                    <img class=" p-5  w-full rounded-xl" alt="hero" src={workB5}/>
                    
                </div>
                <div class=" basis-1/2  text-base text-gray text-center md:text-left">
                    <img class=" p-5  w-full rounded-xl" alt="hero" src={workB6}/>
                    <img class=" p-5  w-full rounded-xl" alt="hero" src={workB4}/>   
                </div>
            </div>
            
            {/* The Gallery end  */}
            </div>
        </section>
        {/* The Website Section  */}
        <section class="font-light pb-24">
            <div class="relative items-center w-full px-5 py-12 mx-auto md:px-12 lg:px-24 max-w-7xl">
                <div class="flex flex-row flex-wrap md:flex-nowrap  w-full gap-0 mx-auto ">
                    
                    <div class=" p-6 md:basis-3/5 text-center md:text-left">
                        <h1 class="text-gray text-3xl lg:text-5xl  mb-3"><span class="text-primary text-xl lg:text:2xl  text-semi">03.<br/></span>Website</h1>
                        <p class="px-3 text-gray leading-relaxed text-base">The website was needed to showcase all the traiings provided and to let others book slots for training and facilities. The ethos and services of brand. The landing page gives a glimpse of all the partners and directing them to relevant trainings.  </p> <br/>  
                        <p class="px-3 text-gray leading-relaxed text-base">The site is made with less than 1.4 MB to reduce the load time. It was built with vanila Js and integrated to with third party apps to accomdate bookings.   </p><br/>
                        <p class="px-3 text-gray leading-relaxed text-base">The site is made responsive to cater to mobile users. All the colors are picked based on various brands partnered with organizations to further showcase the idea it is culimation of multiple trainings  </p>
                        <img class="p-10 lg:px-30 object-cover object-center w-full rounded-xl" alt="hero" src={workB9}/>
                    </div>
                    <div class=" md:basis-2/5 p-0 text-center md:text-left">
                        <p class="invisible text-8xl md:visible"><br/></p>
                        <img class="py-10  object-cover object-center w-full rounded-xl" alt="hero" src={workB10}/>
                        <img class="py-10 scale-150 object-cover object-center w-full rounded-xl" alt="hero" src={workB7}/>
                        <img class="py-10   object-cover object-center w-full rounded-xl" alt="hero" src={workB8}/>
                    </div>
                    
                </div>
            </div>
        </section>
      </>
    )
}
export default Queless