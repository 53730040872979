import React from 'react'
/* import {Link} from 'react-router-dom' */
import heroB from '../../images/work/Ombrello.png'
import logoB from '../../images/work/Ombrello/logo.gif'
import logoU from '../../images/work/Ombrello/logoU.png'
import logoO from '../../images/work/Ombrello/logoa.png'
import workO1 from '../../images/work/Ombrello/work1.png'
import work02 from '../../images/work/Ombrello/work1.svg'

const Ombrello = () => {

    return (
        <>
        <section class="text-gray-600 body-font w-full font-light">
            <div class="flex flex-col items-center align-middle justify-center py-24 mx-auto rounded-lg max-w-3xl">
                <img class=" w-full rounded-xl" alt="hero" src={heroB}/>
                <h1 class="py-10 max-w-6xl text-2xl text-center font-normal  text-gray md:text-3xl lg:text-3xl lg:max-w-9xl">
                            Ombrello Academy <br/>
                            
                </h1>
            </div>
            
        </section>
        {/* The brief Section  */}
        <section class="font-light">
            <div class="relative items-center w-full px-5 py-12 mx-auto md:px-12 lg:px-24 max-w-7xl">
            <div class="flex flex-row flex-wrap md:flex-nowrap  w-full gap-12 mx-auto ">
                
                <div class=" md:basis-3/4 p-6 text-center md:text-left">
                    <h1 class="text-gray text-3xl lg:text-5xl  mb-3"><span class="text-primary text-xl lg:text:2xl  text-semi">01.<br/></span>The Brief</h1>
                    <p class="px-3 text-gray leading-relaxed text-base">Ombrello Academy is a sports, arts and after school training provider. Ombrello academy wants to become one stop solution and bring everything under one Umbrella. They partnered with top sports organizations NBA, Laliga, White Shark Academy, Madhan Lal Cricket Academy and Rivendale Tennis Academy</p> <br/>
                    <p class="px-3 text-gray leading-relaxed text-base">We joined the team when the organization didnt have any name and developed Brand Identy and website for them to handle online booking</p>  
                </div>
                <div class=" w-full md:basis-1/4 p-6 text-base text-gray text-center md:text-left">
                    <p class="invisible text-8xl md:visible"><br/></p>
                    <p class="pb-5 " ><span class="text-primary text-2xl lg:text:3xl  text-semi">Industry</span><br/> Sports </p>
                    <p class="pb-5 "><span class="text-primary text-2xl lg:text:3xl text-semi">Website</span><br/> https://ombrello.in</p>
                    <p class="pb-5 "><span class="text-primary text-2xl lg:text:3xl text-semi">Services</span><br/> Branding | Logo Design | Brand Communication | UI Design | Web Development | SEO SMM</p>
                </div>
            </div>
            </div>
        </section>

        {/* The Branding Section  */}
        <section class="font-light">
            <div class="relative items-center w-full px-5 py-12 mx-auto md:px-12 lg:px-24 max-w-7xl">
            <div class=" md:basis-3/4 p-6 text-center md:text-left">
                    <h1 class="text-gray text-3xl lg:text-5xl mb-3"><span class="text-primary text-xl lg:text:2xl  text-semi">02.<br/></span>The Branding</h1>
                    <p class="px-3 text-gray leading-relaxed text-base">As per the brief we wanted to showcase that this organization is a place where learners can develop a pallet of skills under one organization. We took multi color representing multiple offerings minxed it with Pallet of colors. The color selection is bit flashy to showcase that the academy caters to Childrean rather than adults</p>   
                    <div class="flex justify-center py-36">
                        <img class="  h-28 lg:h-60 lg:px-30 items-center  rounded-xl" alt="hero" src={logoO}/>
                        <img class="  h-28 lg:h-60 lg:px-30  items-center rounded-xl" alt="hero" src={logoU}/>
                        <img class="  h-28 lg:h-60 lg:px-30  items-center rounded-xl" alt="hero" src={logoB}/>
                    </div>
                    
                    <p class="px-3 text-gray leading-relaxed text-base">We picked the Italian word Ombrello that translates into english word Umbrella and incorporated our logo into the word by replacing O.</p>
                    <img class=" p-10 w-full lg:h-60 lg:px-30  rounded-xl" alt="hero" src={work02}/>
            </div>

            </div>
        </section>
        {/* The Website Section  */}
        <section class="font-light">
            <div class="relative items-center w-full px-5 py-12 mx-auto md:px-12 lg:px-24 max-w-7xl">
            <div class="flex flex-row flex-wrap md:flex-nowrap  w-full gap-12 mx-auto ">
                
                <div class=" md:basis-3/4 p-6 text-center md:text-left">
                    <h1 class="text-gray text-3xl lg:text-5xl  mb-3"><span class="text-primary text-xl lg:text:2xl  text-semi">03.<br/></span>Website</h1>
                    <p class="px-3 text-gray leading-relaxed text-base">The translate the philosphy not to limit the child to specific knoledge or skills but to unlock their potential to acquire unlimited knowledge around the world for the key for our branding. This should reflect in school identity. </p>   
                    <img class="p-10 lg:px-30 object-cover object-center w-full rounded-xl" alt="hero" src={workO1}/>
                </div>
                
            </div>
            </div>
        </section>
      </>
    )
}

export default Ombrello