import React from 'react'
import Contactform from '../components/Contactform.jsx';

const contact = () => {
    return (
        <>
            
            <h1 class="text-gray  px-5 py-24 mx-auto text-5xl font-bold mb-3 text-center" >Lets get Giiki'ed</h1>
            

            <section class="container max-w-6xl px-5 py-24 mx-auto ">
                <Contactform />
            </section>
        </>
    )
}

export default contact
