import React, {useRef} from 'react'
/* import { useForm } from "react-hook-form"; */
import emailjs from '@emailjs/browser'; 


    


const Contactform = () => {

    
    const form =useRef();
    

    const sendEmail = (e) => {
        e.preventDefault();
        
        emailjs.sendForm('service_j1kv6hj', 'template_fxf81ty', form.current, 'nAzEDawFKUYn3V4bP').then((result) => {
          
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        e.target.reset();
    }

    return (
        
        <>
                <h1 className="text-center text-4xl font-semibold mt-10">Share your thoughts</h1>
                <form ref={form} className="max-w-xl m-auto py-10 mt-10 px-12 border" onSubmit={sendEmail}>
                    <label className="text-gray-600 font-medium">Name</label>
                    <input className="border-solid border-gray-300 border py-2 px-4 w-full rounded text-gray-700" name="user_name" placeholder="John Doe" autoFocus />
                    

                    <label className="text-gray-600 font-medium block mt-4">email</label>
                    <input className="border-solid border-gray-300 border py-2 px-4 w-full rounded text-gray-700" name="user_email" type="text" placeholder="John@doe.com"/>
                    
                    <label className="text-gray-600 font-medium block mt-4">Phone</label>
                    <input className="border-solid border-gray-300 border py-2 px-4 w-full rounded " name="link" type="user_phone" placeholder="91 8143007555"/>

                    <label className="text-gray-600 font-medium block mt-4">Thoughts </label>
                    <textarea className="border-solid border-gray-300 border py-20 px-4 w-full rounded " name="message" rows={5} cols={5}/>
                    
                    <button className="mt-4 w-full bg-green-400 hover:bg-primary  border hover:border-white py-3 px-6 font-semibold text-md rounded" type="submit">
                    Submit
                    </button>
                </form>
        </>        
    );
}

export default Contactform
