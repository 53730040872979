import React from 'react'
import {Link} from 'react-router-dom'

const Footer = () => {
    return (
            <footer class=" bg-black tx">
                <div class="container px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col background">
                    <div class="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
                    <a href="/" class="flex font-medium items-center md:justify-start justify-center text-gray">
                    <svg width="109" height="128" viewBox="0 0 109 128" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="1" y="1" width="107" height="98.9677" fill="white" stroke="#DADADA" stroke-width="2"/>
                            <rect x="0.977539" y="51.1658" width="106.556" height="48.6646" rx="2" fill="#96DED1"/>
                            <path d="M23.672 115.668H25.448V119.076C24.992 119.42 24.464 119.684 23.864 119.868C23.264 120.052 22.66 120.144 22.052 120.144C21.18 120.144 20.396 119.96 19.7 119.592C19.004 119.216 18.456 118.7 18.056 118.044C17.664 117.38 17.468 116.632 17.468 115.8C17.468 114.968 17.664 114.224 18.056 113.568C18.456 112.904 19.008 112.388 19.712 112.02C20.416 111.644 21.208 111.456 22.088 111.456C22.824 111.456 23.492 111.58 24.092 111.828C24.692 112.076 25.196 112.436 25.604 112.908L24.356 114.06C23.756 113.428 23.032 113.112 22.184 113.112C21.648 113.112 21.172 113.224 20.756 113.448C20.34 113.672 20.016 113.988 19.784 114.396C19.552 114.804 19.436 115.272 19.436 115.8C19.436 116.32 19.552 116.784 19.784 117.192C20.016 117.6 20.336 117.92 20.744 118.152C21.16 118.376 21.632 118.488 22.16 118.488C22.72 118.488 23.224 118.368 23.672 118.128V115.668ZM37.2538 111.6H39.1978V120H37.2538V111.6ZM51.1913 111.6H53.1353V120H51.1913V111.6ZM68.1888 116.7L67.0608 117.876V120H65.1288V111.6H67.0608V115.524L70.7808 111.6H72.9408L69.4608 115.344L73.1448 120H70.8768L68.1888 116.7ZM84.0116 111.6H85.9556V120H84.0116V111.6Z" fill="#878787"/>
                            <path d="M54.355 24.7291L30.0343 49.0498C29.6437 49.4403 29.6437 50.0735 30.0343 50.464L53.6479 74.0776C54.0384 74.4682 54.6716 74.4682 55.0621 74.0776L77.6757 51.464C78.3056 50.8341 77.8595 49.7569 76.9686 49.7569H54.355" stroke="#878787" stroke-opacity="0.9"/>
                            <path d="M54.3545 23.4619V73.2007" stroke="#878787" stroke-opacity="0.9"/>
                            <path d="M80.6495 75.26L55.8536 50.464C55.4631 50.0735 55.4631 49.4403 55.8536 49.0498L80.6495 24.2539" stroke="#878787" stroke-opacity="0.9"/>
                        </svg>
                        
                    </a>
                    <p class="mt-2 text-sm text-gray">We are group of  creative people who enjoy showing up and doing something we love every day.</p>
                    </div>
                    <div class="flex-grow flex flex-wrap md:pl-20 -mb-10 md:mt-0 mt-10 md:text-left text-center ">
                    <div class="lg:w-1/2  md:w-1/2 w-full px-6">
                        <h2 class="text-4xl font-bold text-gray tracking-widest  mb-3">GIIKI</h2>
                        <nav class="list-none mb-10">
                        <li>
                            <Link className="text-gray" to="/work">Work</Link>
                        </li>
                        <li>
                            <Link className="text-gray" to="/services">Services</Link>
                
                        </li>
                        <li>
                            <Link className="text-gray" to="/team">Team</Link>
                
                        </li>
                        <li>
                            <Link className="text-gray" to="/contact">Contact</Link>
                        </li>
                        </nav>
                    </div>
                    <div class="lg:w-1/2 md:w-1/2 w-full px-6">
                        <h2 class=" text-4xl font-bold text-gray tracking-widest  mb-3">Subscribe</h2>
                        
                        <div class=" rounded-lg md:ml-auto w-full mt-10 md:mt-0">
                            <div class="relative mb-4">
                                <label for="email" class="leading-7 text-lg py-10 text-gray">Email</label>
                                <input type="email" id="email" name="email" class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
                            </div>
                            <button class=" text-white bg-primary border-0 py-2 px-8 focus:outline-none hover:bg-white hover:text-black rounded-xl text-lg">Subscribe</button>
                            <p class="text-xs text-gray-500 mt-3">Literally you probably haven't heard of them jean shorts.</p>
                        </div>


                    </div>
                    
                    </div>
                </div>
                <div class="bg-gray-100">
                    <div class="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
                    <p class="text-gray text-sm text-center sm:text-left">
                        Copyright © 2020 GIIKI Agency. All rights reserved.
                    </p>
                    <span class="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start">
                        <a href="/" class="text-gray">
                        <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                            <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                        </svg>
                        </a>
                        <a href="/" class="ml-3 text-gray">
                        <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                            <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
                        </svg>
                        </a>
                        <a href="/" class="ml-3 text-gray">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5" viewBox="0 0 24 24">
                            <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
                            <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                        </svg>
                        </a>
                        <a href="/" class="ml-3 text-gray">
                        <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="0" class="w-5 h-5" viewBox="0 0 24 24">
                            <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"></path>
                            <circle cx="4" cy="4" r="2" stroke="none"></circle>
                        </svg>
                        </a>
                    </span>
                    </div>
                </div>
            </footer>
        
    )
}

export default Footer
