import React from 'react'
/* import {Link} from 'react-router-dom' */
import heroB from '../../images/work/Bloomingdale.png'
import logoB from '../../images/work/Bloomingdale/backdrop.png'
import workB1 from '../../images/work/Bloomingdale/Brochure.png'
import workB2 from '../../images/work/Bloomingdale/brochure2.png'
import workB3 from '../../images/work/Bloomingdale/bus.png'
import workB4 from '../../images/work/Bloomingdale/car.png'
import workB5 from '../../images/work/Bloomingdale/hoarding.png'
import workB6 from '../../images/work/Bloomingdale/book.png'
import workB7 from '../../images/work/Bloomingdale/hoarding2.png'
import workB8 from '../../images/work/Bloomingdale/website.png'


const Bloomingdale = () => {

    return (
        <>
        <section class="text-gray-600 body-font w-full font-light">
            <div class="flex flex-col items-center align-middle justify-center py-24 mx-auto rounded-lg max-w-3xl">
                <img class="object-cover object-center w-full rounded-xl" alt="hero" src={heroB}/>
                <h1 class="py-10 max-w-6xl text-2xl text-center font-normal  text-gray md:text-3xl lg:text-3xl lg:max-w-9xl">
                            Bloomingdale International <br/>
                            School
                </h1>
            </div>
            
        </section>
        {/* The brief Section  */}
        <section class="font-light">
            <div class="relative items-center w-full px-5 py-12 mx-auto md:px-12 lg:px-24 max-w-7xl">
            <div class="flex flex-row flex-wrap md:flex-nowrap  w-full gap-12 mx-auto ">
                
                <div class=" md:basis-3/4 p-6 text-center md:text-left">
                    <h1 class="text-gray text-3xl lg:text-5xl  mb-3"><span class="text-primary text-xl lg:text:2xl  text-semi">01.<br/></span>The Brief</h1>
                    <p class="px-3 text-gray leading-relaxed text-base">Bloomingdale is one of our first projects. Our team was part since inception and integral part of Branding. Bloomingdale believes that schools should not just prepare students for college entrance but focus on building skills to learn, process and apply any new knowledge/skills. We had a huge challenge of communicating this philosophy to the local market without using the traditional advertising sources considering the industry expectations. Trust in the philosophy will translate into higher enrolments. This was a new challenge since the school is placed in a small suburban town that requires the first movers to take additional steps to gain the trust of the community.</p>   
                </div>
                <div class=" w-full md:basis-1/4 p-6 text-base text-gray text-center md:text-left">
                    <p class="invisible text-8xl md:visible"><br/></p>
                    <p class="pb-5 " ><span class="text-primary text-2xl lg:text:3xl  text-semi">Industry</span><br/> Education </p>
                    <p class="pb-5 "><span class="text-primary text-2xl lg:text:3xl text-semi">Website</span><br/> https://bloomingdale.edu.in</p>
                    <p class="pb-5 "><span class="text-primary text-2xl lg:text:3xl text-semi">Services</span><br/> Branding | Logo Design | Brand Communication | UI Design | Web Development | SEO SMM</p>
                </div>
            </div>
            </div>
        </section>

        {/* The Branding Section  */}
        <section class="font-light">
            <div class="relative items-center w-full px-5 py-12 mx-auto md:px-12 lg:px-24 max-w-7xl">
            <div class=" md:basis-3/4 p-6 text-center md:text-left">
                    <h1 class="text-gray text-3xl lg:text-5xl mb-3"><span class="text-primary text-xl lg:text:2xl  text-semi">02.<br/></span>The Branding</h1>
                    <p class="px-3 text-gray leading-relaxed text-base">To translate the philosphy is not to limit the child to specific knoledge or skills but to unlock their potential to acquire unlimited knowledge around the world for the key for our branding. This should reflect in school identity. </p>   
                    <img class=" p-10 lg:px-30 object-cover object-center w-full rounded-xl" alt="hero" src={logoB}/>
                    <p class="px-3 text-gray leading-relaxed text-base">After weighing several options the symbol infinity was picked to showcase the infinity potential inside every child. Once we set the brand identity we started working on several campaings, stationary, website etc. Over the years our straegy varied from improving Brand Recognation to building the brand culture.</p>
            </div>

            {/* The Gallery   */}
            <div class="flex flex-row flex-wrap md:flex-nowrap  w-full gap-0 mx-auto ">
                
                <div class=" overflow-hidden md:basis-1/2 text-center md:text-left">
                    <img class=" p-5 object-cover object-center w-full rounded-xl hover:scale-125" alt="hero" src={workB1}/>
                    <img class=" p-5 object-cover object-center w-full rounded-xl" alt="hero" src={workB3}/>
                    <img class=" p-5 object-cover object-center w-full rounded-xl" alt="hero" src={workB5}/>
                    <img class=" p-5 object-cover object-center w-full rounded-xl" alt="hero" src={workB7}/> 
                </div>
                <div class=" basis-1/2  text-base text-gray text-center md:text-left">
                    <img class=" p-5  object-cover object-center w-full rounded-xl" alt="hero" src={workB2}/>
                    <img class=" p-5  object-cover object-center w-full rounded-xl" alt="hero" src={workB4}/>  
                    <img class=" p-5  object-cover object-center w-full rounded-xl" alt="hero" src={workB6}/>
                    
                </div>
            </div>
            
            </div>
        </section>
        {/* The Website Section  */}
        <section class="font-light">
            <div class="relative items-center w-full px-5 py-12 mx-auto md:px-12 lg:px-24 max-w-7xl">
            <div class="flex flex-row flex-wrap md:flex-nowrap  w-full gap-12 mx-auto ">
                
                <div class=" md:basis-3/4 p-6 text-center md:text-left">
                    <h1 class="text-gray text-3xl lg:text-5xl  mb-3"><span class="text-primary text-xl lg:text:2xl  text-semi">03.<br/></span>Website</h1>
                    <p class="px-3 text-gray leading-relaxed text-base">The translate the philosphy not to limit the child to specific knoledge or skills but to unlock their potential to acquire unlimited knowledge around the world for the key for our branding. This should reflect in school identity. </p>   
                    <img class="p-10 lg:px-30 object-cover object-center w-full rounded-xl" alt="hero" src={workB8}/>
                </div>
                
            </div>
            </div>
        </section>
      </>
    )
}

export default Bloomingdale