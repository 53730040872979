

import './App.css';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import Home from './pages/home'
import Services from './pages/services'
import Work from './pages/work'
import Contact from './pages/contact.js'
import Team from './pages/team'
import Cursor from './components/CustomCursor/index'
import { Routes ,Route } from 'react-router-dom';
import Ombrello from './pages/works/ombrello.js'
import Bloomingdale from './pages/works/bloomingdale.js'
import Queless from './pages/works/queless.js'
import Ananth from './pages/works/ananth.js'
import Cinebar from './pages/works/cinebar.js'
import Toddlers from './pages/works/toddlers.js'
import { useEffect } from "react"
import {Helmet} from "react-helmet";


//import {Scrollbars} from 'react-custom-scrollbar'

function App() {
  useEffect(() => {
    const script1 = document.createElement("script")
    const script2 = document.createElement("script")

    script1.src ="https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.esm.js"
    script1.async = true
    script2.src = "https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.js"
    script2.async = true

    document.body.appendChild(script1)
    document.body.appendChild(script2)

    return () => {
      // clean up the script when the component in unmounted
      document.body.removeChild(script1)
      document.body.removeChild(script2)
    }
  }, [])
  
  
  
  
  
  return (
    
    


    <div className=' content-center'>
      <Helmet>
                <meta charSet="utf-8" />
                <title>Giiki Studio</title>
                <link rel="canonical" href="http://giiki.studio/" />
      </Helmet>
      <Cursor/>
      
      <Navbar/>

        <Routes>
          <Route path="/" exact element={<Home/>}/>
          <Route path="/services" element={<Services/>}/>
          <Route path="/work" element={<Work/>}/>
          <Route path="/contact" element={<Contact/>}/>
          <Route path="/team" element={<Team/>}/> 
          <Route path="/works/ananth" element={<Ananth/>}/> 
          <Route path="/works/bloomingdale" element={<Bloomingdale/>}/>
          <Route path="/works/queless" element={<Queless/>}/>
          <Route path="/works/ombrello" element={<Ombrello/>}/>
          <Route path="/works/cinebar" element={<Cinebar/>}/>
          <Route path="/works/toddlers" element={<Toddlers/>}/>
        </Routes>
        
        <Footer/>
        
    </div>
    
  );
}

export default App;
