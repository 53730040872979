import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../images/home/squareLogo.png'
import team1 from '../images/home/team1.jpeg'
import team2 from '../images/home/team2.jpeg'
import work from '../images/home/work.png'

const Home = () => {
    return (

    <div className="p-5 w-full ">
            <main className=" container px-5 py-24 mx-auto flex flex-wrap md:flex-nowrap">
                <div className="flex-col  w-1/2  ">
                    <div className="place-content-center content-center p-50 ">
                        <p className=" max-h-full text-3xl md:6xl lg:text-8xl text-gray font-bold ">Growing<br/> Together <br/>with <br/>Brands</p>
                        <Link className="" to="/work">
                            <button className="bg-transparent hover:bg-primary text-primary font-semibold hover:text-white mt-10 mb-10 py-2 px-4 border-2 border-primary hover:border-transparent rounded-full">VIEW OUR WORK</button>
                        </Link>
                    </div>
                </div>
                <div className=" flex-col w-1/2 " >
                <img className="hover:animate-ping" loading='lazy' src={logo} alt="logo"/>
                </div>
                
            </main>

            {/* Second block  with we pour our heart */}

            <section class="text-gray ">
                <div class="container px-5 py-24 mx-auto flex flex-wrap">
                    <div class="lg:w-1/2 w-full mb-10 lg:mb-0 rounded-lg overflow-hidden">
                        <img alt="feature3" class="object-cover object-center h-full w-full" src={team1}/>
                    </div>
                    <div class="flex flex-col flex-wrap lg:py-6 -mb-10 lg:w-1/2 lg:pl-12 lg:text-left text-center">
                    <div class="flex flex-col mb-10 lg:items-start items-center">
                        <div class="text-primary text-semi w-14 h-12 inline-flex items-center justify-center mb-5">
                            <p>01. Who</p>
                        </div>
                        <div class="flex-grow">
                        <h2 class="text-gray-900 text-4xl font-bold mb-3">We Pour our <div className="icon baseline animate-bounce"><svg   color='#96DED1' data-icon="heart" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-heart fa-w-16 fa-fw fa-2x">
                            <path fill="currentColor" d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z" class="">
                                </path>
                        </svg></div> into every design</h2>
                        <p class="leading-relaxed text-base">We are GIIKI, a creative Digital Agency who empowers ambitious businesses. Our Team of experts specialise in Website, Branding and Digital Marketing.</p>
                        <Link className="" to="/work">
                            <button className="bg-transparent hover:bg-primary text-primary font-semibold hover:text-white mt-10 mb-10 py-2 px-4 border-2 border-primary hover:border-transparent rounded-full">VIEW OUR WORK</button>
                        </Link>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
            


            {/* Third block  with Giiki Specialises in */}

            <section class="text-gray ">
                <div class="container px-5 py-24 mx-auto flex flex-wrap space-y-1">
                    
                    <div class="flex flex-col flex-wrap lg:py-6 -mb-10 w-full lg:w-1/3 lg:pl-12 text-center lg:text-left ">
                    <div class="flex flex-col mb-10 lg:items-start items-center">
                        <div class="text-primary text-semi w-14 h-12 inline-flex items-center justify-center mb-5">
                            <p>02. How</p>
                        </div>
                        <div class="flex-grow ">
                        <h2 class="text-gray text-4xl font-bold mb-3">GIIKI Specialises in:</h2>
                        <ul className="text-primary text-2xl  ">
                            <li>Creative Web Design</li>
                            <li>Web Development</li>
                            <li>Branding</li>
                            <li>Digital Marketing</li>
                            <li>Digital Strategy</li>
                        </ul>
                        
                        <Link className="" to="/work">
                            <button className="bg-transparent hover:bg-primary text-primary font-semibold hover:text-white mt-10 mb-10 py-2 px-4 border-2 border-primary hover:border-transparent rounded-full">VIEW OUR SERVICES</button>
                        </Link>
                        </div>
                    </div>
                    </div>
                    <div class="lg:w-2/3 w-full mb-10 lg:mb-0 rounded-lg overflow-hidden">
                        <img alt="feature" class="object-cover object-center h-full w-full" src={team2}/>
                    </div>
                </div>
            </section>

            {/* Forth  block  work */}
            <section class="text-gray ">
                <div class="container px-5 py-24 mx-auto flex flex-wrap space-y-1">
                    
                    <div class="w-full lg:w-1/3 py-6 mb-10 lg:mb-0 rounded-lg overflow-hidden">
                        <img alt="feature2" class="object-cover object-center h-full w-full" src={work}/>
                    </div>

                    <div class="flex flex-col flex-wrap lg:py-6 -mb-10 w-full lg:w-2/3 lg:pl-12 text-center lg:text-left ">
                    <div class="flex flex-col mb-10 lg:items-start items-center">
                        <div class="text-primary text-semi w-14 h-12 inline-flex items-center justify-center mb-5">
                            <p>03. Work</p>
                        </div>
                        <div class="flex-grow ">
                        <h2 class="text-gray text-4xl font-bold mb-3">GIIKI is expert in bringing<br/> brands to life</h2>
                        <p class="leading-relaxed text-base">GIIKI is a digital agency passionate about storytelling ,visual design and technology. We collaborate with companies small to large around the world to help them engage their audiences and build brand awareness.</p>
                        
                        <Link className="" to="/work">
                            <button className="bg-transparent hover:bg-primary text-primary font-semibold hover:text-white mt-10 mb-10 py-2 px-4 border-2 border-primary hover:border-transparent rounded-full">VIEW OUR WORK</button>
                        </Link>
                        </div>
                    </div>
                    </div>
                    
                </div>
            </section>
            {/* Fifth block 360 degree agency */}
            <section class="text-gray ">
                <div class="container  max-w-xl  px-5 py-24 mx-auto space-y-1">
                    
                    <p className=" text-4xl font-bold text-left  spin-slow"> 360 of</p>
                    <p className=" text-4xl font-bold text-center">Digital Service Expertise</p>
                    <p className=" text-right">From Corporate Identity to website development<br/>and digital campaigns, we help brands dazzle<br/>their audience and grow their bottom line with<br/>visible results</p>
                    <div class="text-primary text-semi w-max  inline-flex items-center justify-center mb-5">   
                        <Link className=" " to="/services">
                                <button className=" items-center bg-transparent hover:bg-primary text-primary font-semibold hover:text-white mt-10 mb-10 py-2 px-4 border-2 border-primary hover:border-transparent rounded-full">VIEW OUR SERVICES</button>
                        </Link>
                    </div>
                    
                </div>
            </section>

            {/* Sixth what we do and how */}

            <section class="text-gray body-font  ">
                <div class="container px-5 py-24 mx-auto">
                    <div class="flex flex-wrap -m-4">
                    <div class=" p-4 md:w-1/3">
                        <div class="bg-primary shadow-lg h-full rounded-lg overflow-hidden">
                            <p className="transform md:-rotate-90 text-2xl lg:text-3xl font-semibold h-150 p-10">Web <br/>Devlopment</p>
                            <div class="p-6 md:pt-20 lg:pt-40">
                                <h2 class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">CATEGORY</h2>
                                <h1 class="title-font text-lg font-medium text-gray-900 mb-3">Creating great Web Experiences</h1>
                                <p class="leading-relaxed mb-3">is the sole reason we’re in business. you can count on us to design outstanding work in every pixel.Our team plays with cutting edge technology and innovative ideas and an expert UX/UI team.ready to roll.</p>
                                
                            </div>
                        </div>
                    </div>
                    <div class="p-4 md:w-1/3">
                        <div class="bg-primary shadow-lg h-full   rounded-lg overflow-hidden">
                            <p className="transform md:-rotate-90 text-2xl lg:text-3xl font-semibold h-150 p-10">Digital <br/>Marketing</p>
                            <div class="p-6 md:pt-20 lg:pt-40">
                                <h2 class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">CATEGORY</h2>
                                <h1 class="title-font text-lg font-medium text-gray-900 mb-3">Combining quantitative data with qualitative Insights</h1>
                                <p class="leading-relaxed mb-3">we can craft a powerful message that will capture attention and drive conversions. Our campaign capabilities help brands enrich and amplify the way they communicate with the world. around them.</p>
                                
                            </div>
                        </div>
                    </div>
                    <div class="p-4 md:w-1/3">
                        <div class="bg-primary shadow-lg  h-full  rounded-lg overflow-hidden">
                            <p className='transform md:-rotate-90 text-2xl lg:text-3xl font-semibold h-150 p-10'>Corparate <br/>Identity</p>
                            <div class="p-6 md:pt-20 lg:pt-40">
                                <h2 class="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">CATEGORY</h2>
                                <h1 class="title-font text-lg font-medium text-gray-900 mb-3">Building a memorable logo</h1>
                                <p class="leading-relaxed mb-3">is at the core of a great digital presence. If your branding is missing clarity and meaning or needs a little beauty lift, our team is help. Creating the entire brand identity of a business is an exciting task, and we can build out all aspects covering the logo/mark, brand guidelines and related print material. Let GIIKI give you the "WOW" FACTOR!</p>
                                
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>


    </div>
    )
}

export default Home
