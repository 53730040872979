import React from 'react'
/* import { Link } from 'react-router-dom' */
import team1 from '../images/services/services.svg'

const services = () => {
    return (
        <div>
            <section class="text-gray ">
                <div class="container max-w-6xl px-5 py-24 mx-auto flex flex-wrap">
                    <div class="lg:w-1/2 w-full mb-10 lg:mb-0 rounded-lg overflow-hidden">
                        <img alt="feature4" class=" px-10 py-10 object-center  w-full" src={team1}/>
                    </div>
                    <div class="flex flex-col flex-wrap lg:py-6 -mb-10 lg:w-1/2 lg:pl-12 lg:text-left text-center">
                        <div class="flex flex-col mb-10 lg:items-start items-center">
                            
                            <div class="flex-grow">
                                <h1 class="text-gray-900 text-5xl font-bold mb-3">
                                   The Brief
                                </h1>
                                <p class="leading-relaxed text-base">GIIKI is an experienced agency in web design, SEO , we development, branding. We can execute anything that is customer facing from campaign strategy, media plan, design offline materials.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Second block  with web design */}

             <section class="text-gray ">
                <div class="container max-w-5xl px-5 py-10 mx-auto flex flex-wrap ">
                    <div class="lg:w-1/2  p-10 w-full mb-10 lg:mb-0 rounded-lg   lg:text-left text-center">
                                <h1 class="text-gray-900 text-6xl font-bold mb-3">
                                    <span class="text-primary">Web</span>Design
                                </h1>
                                <p class="text-2xl font-medium">Designing a website means creating a user journey end to end.</p>
                                <p class="font-light text-2xl">GIIKI does a solid planning, a touch pf storytelling. From sketches to whiteboard, we think about each project from every angle and every marketing platform.</p>
                    </div>
                    <div class=" w-full lg:py-10 -mb-10 lg:w-1/2 lg:pl-12 lg:text-left text-center">
                        <div class="flex-grow lg:items-start items-center">
                            
                            <div class=" lg:items-start items-center">
                                <p class="justify-center font-light text-lg py-5">
                                    GIIKI Can help you with:
                                </p>
                                <ul class=" px-10 list-outside lg:list-disc list-none text-lg font-medium ">
                                    <li>Product and service website</li>
                                    <li>UI/UX Designs</li>
                                    <li>Front and Back-end Development</li>
                                    <li>SEO</li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* third block  with brand identity */}

            <section class="text-gray ">
                <div class="container max-w-5xl px-5 py-10 mx-auto flex flex-wrap ">
                    <div class="lg:w-1/2  p-10 w-full mb-10 lg:mb-0 rounded-lg overflow-hidden  lg:text-left text-center">
                                <h1 class="text-gray-900 text-6xl font-bold mb-3">
                                    <span class="text-primary">Brand</span>Identity
                                </h1>
                                <p class="text-2xl font-medium">Corporate Identify is the first thing customers have with your business and drive for success.</p>
                                <p class="font-light text-xl">GIIKI can help your brand resonate with its audience and carry on an emotional impact</p>
                    </div>
                    <div class=" w-full lg:py-10 -mb-10 lg:w-1/2 lg:pl-12 lg:text-left text-center">
                        <div class="flex flex-col mb-10 lg:items-start items-center">
                            
                            <div class="flex-grow lg:items-start items-center">
                                <p class="justify-center font-light text-lg py-5">
                                    GIIKI Can help you with:<br/>
                                    
                                </p>
                                <ul class="px-10 list-outside lg:list-disc list-none text-lg font-medium ">
                                    <li>Brand identity  development</li>
                                    <li>Logo creation</li>
                                    <li>Brand Guidelines</li>
                                    <li>Corporate Strationary</li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* forth block  with digital maRKETING */}

            <section class="text-gray pb-36">
                <div class="container max-w-5xl px-5 py-10 mx-auto flex flex-wrap ">
                    <div class="lg:w-1/2  p-10 w-full mb-10 lg:mb-0 rounded-lg overflow-hidden  lg:text-left text-center">
                                <h1 class="text-gray-900 text-6xl font-bold mb-3">
                                    <span class="text-primary">Digital</span>Marketing
                                </h1>
                                <p class="text-2xl font-medium">GIIKI uses storytelling to create a deeper connection with your audience.</p>
                                <p class="font-light text-xl">We brainstorm multi- channel marketing tactics to get you noticed. We leverage the power of imaginations and accuracy of data to revise, repeat whats wrong. And thats how magic happens.</p>
                    </div>
                    <div class=" w-full lg:py-10 -mb-10 lg:w-1/2 lg:pl-12 lg:text-left text-center">
                        <div class="flex flex-col mb-10 lg:items-start items-center">
                            
                            <div class="flex-grow lg:items-start items-center">
                                <p class="font-light text-lg py-5">
                                    GIIKI Can help you with:
                                </p>
                                <ul class="px-10 list-outside lg:list-disc list-none text-lg font-medium ">
                                    <li>Campaign startegy & planing</li>
                                    <li>Google adwords/PPC</li>
                                    <li>Content Marketing</li>
                                    <li>Social Media Marketing</li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default services
