import React from 'react'
/* import { Link } from 'react-router-dom' */
import team1 from '../images/team/studio.png'
import team1l from '../images/team/1l.jpeg'
import team2 from '../images/team/2.jpeg'
import team3 from '../images/team/3.jpeg'
import team4 from '../images/team/4.jpeg'
import team5 from '../images/team/5.jpeg'
import team6 from '../images/team/6.jpeg'
import team7 from '../images/team/team.png'

const Team = () => {
    return (
        <>
          <section class="text-gray ">
                <div class="container max-w-6xl px-5 py-24 mx-auto flex flex-wrap">
                    <div class="lg:w-1/2 w-full mb-10 lg:mb-0 rounded-lg overflow-hidden">
                        <img alt="feature5" class=" px-10 py-10 object-center  w-full" src={team1}/>
                    </div>
                    <div class="flex flex-col flex-wrap lg:py-6 -mb-10 lg:w-1/2 lg:pl-12 lg:text-left text-center">
                        <div class="flex flex-col mb-10 lg:items-start items-center">
                            
                            <div class="flex-grow">
                                <h1 class="text-gray-900 text-3xl md:6xl font-bold mb-3">
                                  A boutique team of designers, developers & Strategists.
                                </h1>
                                <p class="font-light  leading-relaxed text-base">GIIKI team of experts is made up of creatives with technical know-how strategists who think outside the box, and developers who push innovation.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </section>  

            {/* Second block  with Headdings and center text */}
            <section class="text-gray ">
                <div class="container max-w-6xl px-5 py-24 mx-auto flex flex-wrap justify-center text-center">
                    <p class="text-gray-900 text-3xl md:6xl font-bold mb-3">GIIKI's are talented and creative that take business seriously.</p>
                    <p class=" font-light" >We are strategists, researchers, information architects, front/back-end developers, digital marketing experts, Branding, UX designers, digital techies. Together, we transform the ordinary into truly striking. We take every project as our own project and make sure all our team plan, strategies, and create best best creatives.</p><br/>
                    <p class=" font-light">We take every project as our own project and make sure all our team plan, strategies, and create best best creatives.</p><br/>
                    <p class=" font-light">We do what we do because we love it. We are ready to tackle challenges. We are excited to deliver powerful designs. We are thrilled to work with our clients and make things happen.</p><br/>
                    <p class=" font-light">And we are good at it.</p>
                </div>
            </section> 
            
        
            {/* third block  with gallery */}
            <section class="overflow-hidden text-gray-700 pb-40">
              <div class="container px-5 py-2 mx-auto lg:pt-24 lg:px-32">
                <div class="flex flex-wrap -m-1 md:-m-2">
                  <div class="flex flex-wrap w-1/2">
                    <div class="w-1/2 p-1 md:p-2">
                      <img alt="gallery" class="block object-cover object-center w-full h-full rounded-lg"
                        src={team2}/>
                    </div>
                    <div class="w-1/2 p-1 md:p-2">
                      <img alt="gallery2" class="block object-cover object-center w-full h-full rounded-lg"
                        src={team5}/>
                    </div>
                    <div class="w-full p-1 md:p-2">
                      <img alt="gallery3" class="block object-cover object-center w-full h-full rounded-lg"
                        src={team1l}/>
                    </div>
                  </div>
                  <div class="flex flex-wrap w-1/2">
                    <div class="w-full p-1 md:p-2">
                      <img alt="gallery4" class="block object-cover object-center w-full h-full rounded-lg"
                        src={team6}/>
                    </div>
                    <div class="w-1/2 p-1 md:p-2">
                      <img alt="gallery5" class="block object-cover object-center w-full h-full rounded-lg"
                        src={team3}/>
                    </div>
                    <div class="w-1/2 p-1 md:p-2">
                      <img alt="gallery6" class="block object-cover object-center w-full h-full rounded-lg"
                        src={team4}/>
                    </div>
                  </div>
                </div>
              </div>
            </section>
        

            {/* Forth block  with with team */}
            <section class="text-gray ">
                <div class="container max-w-6xl px-5 py-24 mx-auto flex flex-wrap ">
                    <div class="lg:w-1/2 w-full mb-10 lg:mb-0 rounded-lg overflow-hidden">
                        <img alt="feature" class=" px-10 py-10 object-center  w-full" src={team7}/>
                    </div>
                    <div class="flex flex-col lg:py-6 mb-20 lg:w-1/2 lg:pl-12 lg:text-left text-center self-center">
                          <p class=" font-light leading-relaxed text-base">You will find our team working across Creative web Design, Web Development, Branding , and Digital Marketing.</p>
                    </div>
                </div>
            </section>  

            
        
        </>
        
    )
}

export default Team
