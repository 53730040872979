import React from 'react'
import heroB from '../../images/work/Ananth.svg'
import logoB from '../../images/work/Ananth/work1.svg'
import workB1 from '../../images/work/Ananth/work2.svg'


const Ananth = () => {

    return (
        
        <>
        <section class="text-gray-600 body-font w-full font-light">
            <div class="flex flex-col items-center align-middle justify-center py-24 mx-auto rounded-lg max-w-3xl">
                <img class="object-cover object-center w-full rounded-xl" alt="hero" src={heroB}/>
                <h1 class="py-10 max-w-6xl text-2xl text-center font-normal  text-gray md:text-3xl lg:text-3xl lg:max-w-9xl">
                            Ananth Jewels  
                </h1>
            </div>
            
        </section>
        {/* The brief Section  */}
        <section class="font-light">
            <div class="relative items-center w-full px-5 py-12 mx-auto md:px-12 lg:px-24 max-w-7xl">
            <div class="flex flex-row flex-wrap md:flex-nowrap  w-full gap-12 mx-auto ">
                
                <div class=" md:basis-3/4 p-6 text-center md:text-left">
                    <h1 class="text-gray text-3xl lg:text-5xl  mb-3"><span class="text-primary text-xl lg:text:2xl  text-semi">01.<br/></span>The Brief</h1>
                    <p class="px-3 text-gray leading-relaxed text-base">Ananth Diamonds is a established Jewlery store that is trying to improve customer experience by incoperating technolgy into instore shoping. Ananth has created a new CRM that lets them book appointments, check in, Track customer browsed item with notes, follow up with client and close order online</p><br/>
                    <p class="px-3 text-gray leading-relaxed text-base">Ananth screens are designed around their operation cycle. In depth analysis was required to track various flows and new hybrid process to be created based on needs. </p>   
                </div>
                <div class=" w-full md:basis-1/4 p-6 text-base text-gray text-center md:text-left">
                    <p class="invisible text-8xl md:visible"><br/></p>
                    <p class="pb-5 " ><span class="text-primary text-2xl lg:text:3xl  text-semi">Industry</span><br/> Jewlery retail </p>
                    <p class="pb-5 "><span class="text-primary text-2xl lg:text:3xl text-semi">Website</span><br/> CRM (Internal site)</p>
                    <p class="pb-5 "><span class="text-primary text-2xl lg:text:3xl text-semi">Services</span><br/> UI Design | Web Development </p>
                </div>
            </div>
            </div>
        </section>

        {/* The Branding Section  */}
        <section class="font-light pb-32">
            <div class="relative items-center w-full px-5 py-12 mx-auto md:px-12 lg:px-24 max-w-7xl">
            <div class=" md:basis-3/4 p-6 text-center md:text-left">
                    <h1 class="text-gray text-3xl lg:text-5xl mb-3"><span class="text-primary text-xl lg:text:2xl  text-semi">02.<br/></span>The Design</h1>
                    <div class="flex">
                        <div class=" ">
                            <p class="px-3 text-gray leading-relaxed text-base">Esense of Ananth brand was incorparated into the Design system. A brand new design system was created to standardise the template for future development with material UI as the base. </p>   
                            <p class="px-3 text-gray leading-relaxed text-base">The designed are made responsive with primary focus on tablets to support the team working on the floor. </p>
                        </div>
                        <img class=" p-10 lg:px-30 object-cover object-center w-full rounded-xl" alt="work" src={logoB}/>    
                    </div>
                    <img class=" p-10 lg:px-30 object-cover object-center w-full rounded-xl" alt="work2" src={workB1}/>
            </div>
            
            </div>
        </section>
        
      </>
        
    );
}
export default Ananth