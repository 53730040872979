import React from 'react'
/* import {Link} from 'react-router-dom' */
import heroB from '../../images/work/Cinebar.png'
import logoB from '../../images/work/Cinebar/logo.png'



const Cinebar = () => {

    return (
        <>
        <section class="text-gray-600 body-font w-full font-light">
            <div class="flex flex-col items-center align-middle justify-center py-24 mx-auto rounded-lg max-w-3xl">
                <img class="object-cover object-center w-full rounded-xl" alt="hero" src={heroB}/>
                <h1 class="py-10 max-w-6xl text-2xl text-center font-normal  text-gray md:text-3xl lg:text-3xl lg:max-w-9xl">
                            Cine Bar
                </h1>
            </div>
            
        </section>
        {/* The brief Section  */}
        <section class="font-light">
            <div class="relative items-center w-full px-5 py-12 mx-auto md:px-12 lg:px-24 max-w-7xl">
            <div class="flex flex-row flex-wrap md:flex-nowrap  w-full gap-12 mx-auto ">
                
                <div class=" md:basis-3/4 p-6 text-center md:text-left">
                    <h1 class="text-gray text-3xl lg:text-5xl  mb-3"><span class="text-primary text-xl lg:text:2xl  text-semi">01.<br/></span>The Brief</h1>
                    <p class="px-3 text-gray leading-relaxed text-base"> Its a unique location where people can watch movies while enjoying their drinks. We wanted the audience to understand the concept as soon as they look at the Logo. Since most of the target audience are above 40 we tried to incoperate those traditional movie and drink elements into the logo</p>   
                </div>
                <div class=" w-full md:basis-1/4 p-6 text-base text-gray text-center md:text-left">
                    <p class="invisible text-8xl md:visible"><br/></p>
                    <p class="pb-5 " ><span class="text-primary text-2xl lg:text:3xl  text-semi">Industry</span><br/> Bar and Restaurant </p>
                    <p class="pb-5 "><span class="text-primary text-2xl lg:text:3xl text-semi">Website</span><br/> N/A</p>
                    <p class="pb-5 "><span class="text-primary text-2xl lg:text:3xl text-semi">Services</span><br/> Branding | Logo Design </p>
                </div>
            </div>
            </div>
        </section>

        {/* The Branding Section  */}
        <section class="font-light pb-32">
            <div class="relative items-center w-full px-5 py-12 mx-auto md:px-12 lg:px-24 max-w-7xl">
            <div class=" md:basis-3/4 p-6 text-center md:text-left">
                    <h1 class="text-gray text-3xl lg:text-5xl mb-3"><span class="text-primary text-xl lg:text:2xl  text-semi">02.<br/></span>The Branding</h1>
                    <p class="px-3 text-gray leading-relaxed text-base">We choose color orange to associate with food since most of the food places in the Local market use Orange colors. And the main brandmark is based on traditional studio logos. In addition we tried to use negetive space to incoperate bottle into bar.    </p>   
                    <img class=" p-10 lg:px-30 object-cover object-center w-full rounded-xl" alt="hero" src={logoB}/>
                    
            </div>

           
            
            </div>
        </section>
        
      </>
    )
}

export default Cinebar