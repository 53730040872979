import React from 'react'
import { Link } from 'react-router-dom'
import work from '../images/work/work.png'
import bloomingdale from '../images/work/Bloomingdale.png'
import ombrello from '../images/work/Ombrello.png'
import ananth from '../images/work/Ananth.svg'
import queless from '../images/work/Queless.jpg'
import toddlers from '../images/work/Toddlers.png'
import cinebar from '../images/work/Cinebar.png'


const Work = () => {
    return (
        <>
            <section class="text-gray ">
                <div class="container max-w-6xl px-5 py-24 mx-auto flex flex-wrap">
                    <div class="lg:w-1/2 w-full mb-10 lg:mb-0 rounded-lg overflow-hidden">
                        <img alt="feature" class=" px-10 py-10 object-center  w-full" src={work}/>
                    </div>
                    <div class="flex flex-col flex-wrap lg:py-6 -mb-10 lg:w-1/2 lg:pl-12 lg:text-left text-center">
                        <div class="flex flex-col mb-10 lg:items-start items-center place-self-center">
                            
                            
                                <h1 class="text-gray-900 text-3xl md:5xl font-bold mb-3 py-11">
                                    We are experts in bringing brands to life digitally.
                                </h1>
                                <p class="leading-relaxed text-base">GIIKI is a progressive and insightful design agency, technically and creatively skilled to translate your brand into its best digital self. Our design and development approach creates impactful, engaging brands and immersive digital experiences that bring you a return on creativity.</p>
                            
                        </div>
                    </div>
                </div>
            </section>

            <section class="pb-32">
                <div class="bg-white py-6 sm:py-8 lg:py-12">
                    <div class="max-w-screen-2xl px-4 md:px-8 mx-auto">
                        

                        <div class="grid sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-x-4 md:gap-x-6 gap-y-16">
                        {/* <!-- product - start --> */}
                        <div>
                            <Link className="group h-65 block bg-gray-100 rounded-lg overflow-hidden  relative mb-2 lg:mb-3" to="/works/Ombrello">
                                <img src={ombrello} loading="lazy" alt="ombrello site" class="w-full h-full object-contain object-center group-hover:scale-110 transition duration-200" />
                            </Link>
                            <div class="flex justify-between items-start gap-2 px-2">
                                <div class=" px-5 flex flex-col">
                                    <Link className="text-primary hover:text-gray-500 text-lg lg:text-xl font-bold transition duration-100" to="/works/Ombrello">
                                        Ombrello
                                    </Link>
                                    <span class="text-gray font-thin">We took the challange of creating new website for Ombrello</span>
                                </div>
                            </div>
                        </div>
                        {/* <!-- product - end -->

                        <!-- product - start --> */}
                        <div>
                            <Link className="group h-65 block bg-gray-100 rounded-lg overflow-hidden  relative mb-2 lg:mb-3" to="/works/Bloomingdale">
                                <img src={bloomingdale} loading="lazy" alt="bloomingdale mockup" class="w-full h-full object-contain object-center group-hover:scale-110 transition duration-200" />
                            </Link>

                            <div class="flex justify-between items-start gap-2 px-2">
                                <div class=" px-5 flex flex-col">
                                    <Link className="text-primary hover:text-gray-500 text-lg lg:text-xl font-bold transition duration-100" to="/works/Bloomingdale">
                                        Bloomingdale
                                    </Link>
                                    <span class="text-gray font-thin">Complete End to End design services from brand Identity, Website and Digital</span>
                                </div>
                            </div>
                        </div>
                        {/* <!-- product - end -->

                        <!-- product - start --> */}
                        <div>
                            <Link className="group h-65 block bg-gray-100 rounded-lg overflow-hidden  relative mb-2 lg:mb-3" to="/works/Queless">
                                <img src={queless} loading="lazy" alt="queless mockup" class="w-full h-full object-contain object-center group-hover:scale-110 transition duration-200" />
                            </Link>

                            <div class="flex justify-between items-start gap-2 px-2">
                                <div class=" px-5 flex flex-col">
                                    <Link className="  text-primary hover:text-gray-500 text-lg lg:text-xl font-bold transition duration-100" to="/works/Queless">
                                        Queless
                                    </Link>
                                    <span class=" font-thin">Brand Identity and UI service for an food ordering app</span>
                                </div>
                            </div>
                        </div>
                        {/* <!-- product - end -->

                        <!-- product - start --> */}
                        <div>
                            <Link className="group h-65 block bg-gray-100 rounded-lg overflow-hidden  relative mb-2 lg:mb-3" to="/works/Ananth">
                                <img src={ananth} loading="lazy" alt="Ananth Mockup" class="w-full h-full object-contain object-center group-hover:scale-110 transition duration-200" />
                            </Link>

                            <div class="flex justify-between items-start gap-2 px-2">
                                <div class="px-5 flex flex-col">
                                    <Link className="  text-primary hover:text-gray-500 text-lg lg:text-xl font-bold transition duration-100" to="/works/Ananth">
                                        Ananth
                                    </Link>
                                    <span class="text-gray font-thin">CRM devlopment for Ananth</span>
                                </div>
                            </div>
                        </div>
                        {/* <!-- product - end -->

                        <!-- product - start --> */}
                        <div>
                            <Link className="group h-65 block bg-gray-100 rounded-lg overflow-hidden  relative mb-2 lg:mb-3" to="/works/Toddlers">
                                <img src={toddlers} loading="lazy" alt="Toddlers Mockup" class="w-full h-full object-contain object-center group-hover:scale-110 transition duration-200" />
                            </Link>

                            <div class="flex justify-between items-start gap-2 px-2">
                                <div class="px-5 flex flex-col">
                                    <Link className="  text-primary hover:text-gray-500 text-lg lg:text-xl font-bold transition duration-100" to="/works/Toddlers">
                                        Toddlers
                                    </Link>
                                    <span class="text-gray font-thin">Website design and devlopment for Toddlers</span>
                                </div>
                            </div>
                        </div>
                        {/* <!-- product - end -->

                        <!-- product - start --> */}
                        <div>
                            <Link className="group h-65 block bg-gray-100 rounded-lg overflow-hidden  relative mb-2 lg:mb-3" to="/works/Cinebar">
                                <img src={cinebar} loading="lazy" alt="cinebar Mockup" class="w-full h-full object-contain object-center group-hover:scale-110 transition duration-200" />
                            </Link>

                            <div class="flex justify-between items-start gap-2 px-2">
                                <div class="px-5 flex flex-col">
                                    <Link className="  text-primary hover:text-gray-500 text-lg lg:text-xl font-bold transition duration-100" to="/works/Cinebar">
                                        Cinebar
                                    </Link>
                                    <span class="text-gray font-thin">Logo Design for Cinebar</span>
                                </div>
                            </div>
                        </div>
                        {/* <!-- product - end --> */}

                        
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Work
