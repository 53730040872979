import React, { useState } from "react";
import {Link} from 'react-router-dom'
import logo from '../images/logo.svg'
import Button from "./Button";




const Navbar = () => {
    const [open, setOpen] = useState(false);
    return (
        <>
        

        <nav className=" md:bg-white">
            <div className="flex items-center font-medium text-gray justify-around">
                <div className="z-50 p-5 md:w-auto w-full flex justify-between">
                    <img src={logo} alt="logo" className="md:cursor-pointer h-14" />
                    <div className="text-3xl md:hidden" onClick={() => setOpen(!open)}>
                        <ion-icon name={`${open ? "close" : "menu"}`}></ion-icon> 
                    </div>
                </div>
                <ul className="md:flex hidden uppercase items-center gap-8 ">
                <li><Link to="/" className="py-7 px-3 inline-block">Home</Link></li>
                <li><Link className="py-7 px-3 inline-block" to="/work">Work</Link></li>
                <li><Link className="py-7 px-3 inline-block" to="/services">Services</Link></li>
                <li><Link className="py-7 px-3 inline-block" to="/team">Team</Link></li>
                
                </ul>
                <div className="md:block hidden">
                    <Link className="py-5  inline-block" to="/contact"><Button /></Link> 
                </div>


                {/* Mobile nav */}
                <ul className={`md:hidden  bg-white fixed w-full  top-0 overflow-y-auto bottom-0 py-24 pl-4 duration-500 ${open ? "" : "hidden"}`}>
                    <li><Link to="/" className="py-5  inline-block"><button onClick={() => setOpen(!open)}>Home</button></Link></li>
                    <li><Link className="py-5  inline-block" to="/work"><button onClick={() => setOpen(!open)}>Work</button></Link></li>
                    <li><Link className="py-5  inline-block" to="/services"><button onClick={() => setOpen(!open)}>Services</button></Link></li>
                    <li><Link className="py-5  inline-block" to="/team"><button onClick={() => setOpen(!open)}>Team</button></Link></li>
                
                    <div className="py-5">
                        <Link className="py-5  inline-block" to="/contact"><button onClick={() => setOpen(!open)}><Button /></button></Link> 
                    </div>
                </ul>
            </div>
        </nav>


        <script type="module" src="https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.esm.js"></script>
        <script nomodule src="https://unpkg.com/ionicons@5.5.2/dist/ionicons/ionicons.js"></script>
       </>
    );
};

export default Navbar
