import React from 'react'
/* import {Link} from 'react-router-dom' */
import heroB from '../../images/work/Toddlers.png'
import workB8 from '../../images/work/Toddlers/website.png'
import logoT from '../../images/work/Toddlers/logo.jpg'


const Toddlers = () => {

    return (
        <>
        <section class="text-gray-600 body-font w-full font-light">
            <div class="flex flex-col items-center align-middle justify-center py-10 lg:py-24 mx-auto rounded-lg max-w-3xl">
                <img class="object-cover object-center w-full rounded-xl" alt="hero" src={heroB}/>
                <h1 class="pt-10 max-w-6xl text-2xl text-center font-normal  text-gray md:text-3xl lg:text-3xl lg:max-w-9xl">
                            Toddlers Play School <br/>
                            
                </h1>
            </div>
            
        </section>
        {/* The brief Section  */}
        <section class="font-light">
            <div class="relative items-center w-full px-5 py-12 mx-auto md:px-12 lg:px-24 max-w-7xl">
            <div class="flex flex-row flex-wrap md:flex-nowrap  w-full gap-12 mx-auto ">
                
                <div class=" md:basis-3/4 p-6 text-center md:text-left">
                    <h1 class="text-gray text-3xl lg:text-5xl  mb-3"><span class="text-primary text-xl lg:text:2xl  text-semi">01.<br/></span>The Brief</h1>
                    <p class="px-3 text-gray leading-relaxed text-base">Toddlers are one of our early projects. Our team was part of the inception and integral part of Branding. Toddler's playschool implements a play-based curriculum with hands-on activities. We used this element as a part of their logo design. Toddlers make it a happy place for a young learners to play and learn. This is the same emotion we try to deliver on the website </p>   
                    <img class="p-10 lg:px-30 object-cover object-center w-full rounded-xl" alt="hero" src={logoT}/>
                </div>
                <div class=" w-full md:basis-1/4 p-6 text-base text-gray text-center md:text-left">
                    <p class="invisible text-8xl md:visible"><br/></p>
                    <p class="pb-5 " ><span class="text-primary text-2xl lg:text:3xl  text-semi">Industry</span><br/> Education </p>
                    <p class="pb-5 "><span class="text-primary text-2xl lg:text:3xl text-semi">Website</span><br/> https://toddlers.edu.in</p>
                    <p class="pb-5 "><span class="text-primary text-2xl lg:text:3xl text-semi">Services</span><br/>  UI Design | Web Development | SEO SMM</p>
                </div>
            </div>
            </div>
        </section>

        
        {/* The Website Section  */}
        <section class="font-light">
            <div class="relative items-center w-full px-5 py-12 mx-auto md:px-12 lg:px-24 max-w-7xl">
            <div class="flex flex-row flex-wrap md:flex-nowrap  w-full gap-12 mx-auto ">
                
                <div class=" md:basis-3/4 p-6 text-center md:text-left">
                    <h1 class="text-gray text-3xl lg:text-5xl  mb-3"><span class="text-primary text-xl lg:text:2xl  text-semi">02.<br/></span>Website</h1>
                    <p class="px-3 text-gray leading-relaxed text-base">We showcased the Toddlers website to be playful while making it simple for the parents. To do that we collected multiple events photos that show kids playing and used them across the site. No matter which part of the site you look at you always see a child playing </p>   
                    <img class="p-10 lg:px-30 object-cover object-center w-full rounded-xl" alt="hero" src={workB8}/>
                </div>
                
            </div>
            </div>
        </section>
      </>
    )
}

export default Toddlers